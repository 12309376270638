<template>
    <v-container style="background-color: #fff" fluid>
        <div v-if="loading" class="d-flex justify-center mt-8 mb-8 flex-column align-center">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate/>
            <p class="headline mt-4">{{ $t(`messages['loading data']`) }}</p>
        </div>
        <div v-show="!isEmpty || !loading">
            <div class="d-flex align-center justify-space-between my-4">
                <div class="body-1 d--inline-flex" @click="onBackToPlace()"
                     style="cursor: pointer; color: rgba(0, 0, 0, 0.54)">
                    <v-icon class="mr-2">mdi-arrow-left</v-icon>
                    {{ getPlaceName }}
                </div>
                <div>
                    <v-btn text :href="`/photoset/${getPhotogroup.ID}` | webLink" target="_blank" small color="primary">
                        {{ $t(`actions['open site']`) }}
                    </v-btn>
                </div>
            </div>
            <div class="d-flex justify-space-between align-center">
                <div class="headline font-weight-bold">
                    {{ $t(`headers['photoset']`) }}
                </div>
            </div>
            <h3 class="my-4 heading font-weight-medium">
                {{ getPhotos.length }} {{ $tc('plural.photos', getPhotos.length) }}
            </h3>
            <h3 class="my-4 heading font-weight-medium">
                {{ $t(`headers['photographer']`) }} {{ getPhotogroup.Photographer.Name | getName }}
            </h3>
            <h3 class="my-4 heading font-weight-medium">
                {{ getTime }}
            </h3>

            <div class="photoset-page__overview">
                <div class="photoset-page__feature py-1 ">
                    {{ $t(`rudiments['NML']`) }}:
                    <span v-if="getFeatures.ClientName">{{ getFeatures.ClientName }}</span>
                    <span v-else>{{ $t(`placeholders['not specified']`) }}</span>
                </div>
                <div class="photoset-page__feature py-1">
                    {{ $t(`rudiments['checkout date']`, [getCheckOutDate]) }}
                </div>
                <div class="photoset-page__feature py-1">
                    {{ $t(`rudiments['room number']`) }}:
                    <span v-if="getFeatures.RoomNumber">{{ getFeatures.RoomNumber }}</span>
                    <span v-else>{{ $t(`placeholders['not specified']`) }}</span>
                </div>
                <div class="photoset-page__feature py-1">
                    {{ $t(`rudiments['clients email']`) }}
                    <span v-if="getEmails.length">{{ getEmails }}</span>
                    <span v-else>{{ $t(`placeholders['not specified']`) }}</span>
                </div>
                <div class="photoset-page__feature py-1">
                    {{ $t(`rudiments['clients phone']`) }}
                    <span v-if="getPhones.length">{{ getPhones }}</span>
                    <span v-else>{{ $t(`placeholders['not specified']`) }}</span>
                </div>
            </div>
            <div class="mt-2">
                <photo-actions
                    v-if="canInteract"
                    :place-id="getPhotogroup.Place.ID"
                    :photos="getPhotos"
                    :selected-photos.sync="selected"
                    @reload="loadPhotogroup()"/>

                <grid-photos :selected.sync="selected" :photos="getPhotos" :readonly="!canInteract">
                    <template slot="preview-panel" slot-scope="props">
                        <lightbox-panel v-if="props.photo" :photo="props.photo.self"/>
                    </template>
                </grid-photos>
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import GridPhotos from "../components/photos/GridPhotos"
import LightboxPanel from "../components/photogroups/LightboxPanel"
import * as Format from "../util/format"
import Adapters from "../util/adapters"
import PhotoActions from "../components/photos/PhotoActions"

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { GridPhotos, LightboxPanel, PhotoActions },
    data() {
        return {
            selected: [],
            loading: false,
        }
    },
    head() {
        return {
            toolbar: this.$t("toolbar.photoset"),
            title: this.$t("titles.photoset"),
        }
    },
    fetch({ store, params, redirect }) {
        if (!store.getters["common/isAuthorized"]) {
            return redirect("/login")
        }

        return store.dispatch("photogroup/storePhotogroup", params.id)
    },

    computed: {
        ...mapGetters("photogroup", ["getPhotogroup"]),
        ...mapGetters("common", ["getUser"]),
        isEmpty() {
            return this.getPhotos.length === 0
        },

        isAdmin(){
            return this.getPhotogroup.Place.Admins.includes(this.getUser.ID) || this.getUser.JS
        },

        isPhotographer(){
            return this.getPhotogroup.Place.Photographers.includes(this.getUser.ID)
        },

        canInteract(){
            return this.isAdmin || this.isPhotographer
        },

        getCheckOutDate() {
            return (this.getFeatures.CheckOutDate && this.getFeatures.CheckOutDate !== "0") ? Format.formatDateTime(this.getFeatures.CheckOutDate, "DD.MM.YYYY") : this.$t(`placeholders['not specified']`)
        },

        getPlaceName() {
            return Format.getName(this.getPhotogroup.Place.Name)
        },

        getTime() {
            const { StartTime, EndTime } = this.getPhotogroup.Criterias
            const date = Format.formatDateTime(StartTime * 1000, "DD.MM.YYYY")
            const start = Format.formatDateTime(StartTime * 1000, "HH:mm")
            const end = Format.formatDateTime(EndTime * 1000, "HH:mm")
            return `${date} | ${start} - ${end}`
        },

        getPhotos: function() {
            const photos = Adapters.photos(this.getPhotogroup.Photos)

            return photos.sort((a, b) => {
                return new Date(a.self.Metadata.EXIF.Time * 1000) < new Date(b.self.Metadata.EXIF.Time * 1000) ? 1 : -1
            })
        },

        getFeatures() {
            return this.getPhotogroup.Features
        },

        getPhones() {
            if (!this.getFeatures.Phones.length) {
                return this.$t(`placeholders['not specified']`)
            }
            return this.getFeatures.Phones.filter(f => f !== "").join(", ")
        },

        getEmails() {
            if (!this.getFeatures.Emails.length) {
                return this.$t(`placeholders['not specified']`)
            }
            return this.getFeatures.Emails.filter(f => f !== "").join(", ")
        },
    },
    methods: {
        ...mapActions({
            removePhotos: "photos/removeMany",
            storePhotogroup: "photogroup/storePhotogroup",
        }),

        onBackToPlace() {
            this.$router.push(`/places/${this.getPhotogroup.Place.ID}#photos`)
        },

        loadPhotogroup() {
            this.loading = true
            this.storePhotogroup(this.$route.params.id)
                .finally(() => this.loading = false)
        },

    },
}
</script>
